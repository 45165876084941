import { GameLanguages } from "../container/Enums/Enums";
import { IWordTranslation } from "./ButtonText";

export const getTranslatedPhrase = (word: string, language: string): string => {
  for (let i = 0; i < ErrorText.length; i++) {
    if (ErrorText[i].word === word) {
      let lang = ErrorText[i].languages;
      for (let j = 0; j < lang.length; j++) {
        if (lang[j].language === language) {
          return lang[j].translation;
        }
      }
    }
  }
  return word;
};

const ErrorText: IWordTranslation[] = [
  {
    word: "The answer is correct! Great work team!",
    languages: [
      {
        language: GameLanguages.ENUK,
        translation: "The answer is correct! Great work team!",
      },
      {
        language: GameLanguages.DUTCH,
        translation: "Het antwoord is correct!",
      },
    ],
  },
  {
    word: "The answer is incorrect and your team received a 30 second penalty.",
    languages: [
      {
        language: GameLanguages.ENUK,
        translation:
          "The answer is incorrect and your team received a 30 second penalty.",
      },
      {
        language: GameLanguages.DUTCH,
        translation:
          "Het antwoord is incorrect en jullie ontvangen 30 seconden straftijd.",
      },
    ],
  },
  {
    word: "Have another look and try again.",
    languages: [
      {
        language: GameLanguages.ENUK,
        translation: "Have another look and try again.",
      },
      {
        language: GameLanguages.DUTCH,
        translation: "Probeer het opnieuw.",
      },
    ],
  },
  {
    word: "Please log in again to renew your session.",
    languages: [
      {
        language: GameLanguages.ENUK,
        translation: "Please log in again to renew your session.",
      },
      {
        language: GameLanguages.DUTCH,
        translation: "Log opnieuw in om uw sessie te verlengen.",
      },
    ],
  },
  {
    word: "You are logged in with game code:",
    languages: [
      {
        language: GameLanguages.ENUK,
        translation: "You are logged in with game code:",
      },
      {
        language: GameLanguages.DUTCH,
        translation: "Je bent ingelogd met game code:",
      },
    ],
  },
  {
    word: "The maximum number of players is already logged in. Please try logging in with a different game code.",
    languages: [
      {
        language: GameLanguages.ENUK,
        translation:
          "The maximum number of players is already logged in. Please try logging in with a different game code.",
      },
      {
        language: GameLanguages.DUTCH,
        translation:
          "Het maximum aantal spelers is al ingelogd. Probeer in te loggen met een andere spelcode.",
      },
    ],
  },
  {
    word: "There is an error while processing your request.",
    languages: [
      {
        language: GameLanguages.ENUK,
        translation: "There is an error while processing your request.",
      },
      {
        language: GameLanguages.DUTCH,
        translation:
          "Er is een fout opgetreden bij het verwerken van uw verzoek",
      },
    ],
  },
];
